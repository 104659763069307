import './PleaseUs.css';
import ornement from '../ornement.svg';

function PleaseUs({ isOpened, setIsOpened }) {

  return (
      <div className="please-us-content">
        <h2>La magie continue</h2>
        <img src={ornement} className="please-us-ornement" alt="logo"/>
          <p>
            Votre présence à nos côtés pour célébrer notre mariage est déjà un <strong>immense cadeau</strong>.
            <br/><br/>
            <span>Si toutefois vous souhaitez nous témoigner une attention supplémentaire,</span>
            <br/>nous rêvons de prolonger la magie avec un <strong>voyage de noces</strong>.
            <br/><br/>
            <span>Que ce soit en nageant avec des raies mentas à <strong>Bora Bora </strong></span>
            <br/>ou en parcourant les rizières verdoyantes de <strong>Bali</strong>,
            <br/>votre contribution nous permettra de vivre une <strong>expérience inoubliable</strong>.
          </p>
          <img src={ornement} className="please-us-ornement" alt="logo"/>
          <p>
            Pour ceux qui le souhaitent, vous trouverez notre RIB ci-dessous.
            Une urne sera également présente le jour du mariage.
            <br/>FR76 4061 8803 9700 0402 2832 346
          </p>
          <p>
            <strong>Merci</strong>, Florine Claire et Thibaut
          </p>
      </div>
  );
}

export default PleaseUs;
