import './Countdown.css';

function Countdown() {
    const today = new Date();
    const targetDate = new Date('2025-07-30');
    const differenceInMilliseconds = targetDate - today;
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const daysRemaining = Math.ceil(differenceInMilliseconds / millisecondsPerDay);

    return (
        <p className="countdown-container">
            30 Juillet 2025<br/>
            J-{daysRemaining}
        </p>
    );
}

export default Countdown;
