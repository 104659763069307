import './UsefulInformation.css';
import ornement from '../ornement.svg';
import map from '../france.png';

function UsefulInformation({ isOpened, setIsOpened }) {

  return (
      <div className="useful-information-content">
          <h2>Contacts</h2>
          <img src={ornement} className="useful-information-ornement" alt="logo"/>
          <p>Thibaut Cornolti et Florine Claire Daures
          <br/>Adresse postale : 13 rue du Cheval, 67100 Strasbourg
          <br/>Adresse mail : <a href="mailto:thifloc2025@gmail.com">thifloc2025@gmail.com</a></p>
          <h2>Lieux</h2>
          <img src={ornement} className="useful-information-ornement" alt="logo"/>
          <img src={map} className="useful-information-map" alt="map"/>
          <h2>Hébergements</h2>
          <img src={ornement} className="useful-information-ornement" alt="logo"/>
          <p>Vous trouverez toutes les informations à réception des faire-parts.</p>
      </div>
  );
}

export default UsefulInformation;
