import './ConfirmYourVisit.css';
import { useQuery } from '@tanstack/react-query';
import TextField from '@mui/material/TextField';
import {useState, useEffect} from "react";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
    {
        field: 'first_name',
        headerName: 'PRÉNOM',
        flex: 1,
        headerClassName: 'confirm-your-visit-header',
    },
    {
        field: 'last_name',
        headerName: 'NOM',
        flex: 1,
        headerClassName: 'confirm-your-visit-header',
    },
    {
        field: 'attend',
        headerName: 'VENUE CONFIRMÉE',
        type: 'boolean',
        width: 130,
        headerAlign: 'center',
        headerClassName: 'confirm-your-visit-header',
    },
];

function useDebounce(value, delay) {
    // State to hold the debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set the debounced value after the delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cleanup timeout if value changes or component unmounts
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

function ConfirmYourVisit({ isOpened, setIsOpened }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const debouncedFirstName = useDebounce(firstName, 500);
    const debouncedLastName = useDebounce(lastName, 500);

    const { isLoading, isFetched, error, data } = useQuery({
        queryKey: ['people', debouncedFirstName, debouncedLastName],
        queryFn: () =>
            fetch(`https://66e3f2e684fa527d1e5c.appwrite.global/people?firstName=${debouncedFirstName}&lastName=${debouncedLastName}`).then((res) =>
                res.json(),
            ),
        enabled: debouncedFirstName !== '' && debouncedLastName !== '',
    })

  return (
      <div className="confirm-your-visit-content">
        <div className='confirm-your-visit-inputs'>
          <TextField className='confirm-your-visit-input' label={"Prénom"} onChange={(e) => {
              setFirstName(e.target.value);
          }}/>
          <TextField className='confirm-your-visit-input' label={"Nom"} onChange={(e) => {
              setLastName(e.target.value);
          }}/>
          </div>
          {error && <div>Error: {error.message}</div>}
          {isFetched && <div>
            <DataGrid
                getRowId={(row) => row.$id}
                rows={data?.documents ?? []}
                columns={columns}
                hideFooter
                loading={isLoading}
                slotProps={{
                    loadingOverlay: {
                        variant: 'skeleton',
                        noRowsVariant: 'skeleton',
                    },
                    noRowsOverlay: {
                        variant: 'skeleton',
                    },
                }}
                />
        </div>
        }
      </div>
  );
}

export default ConfirmYourVisit;
