import logo from './label.svg';
import './App.css';
import Countdown from "./Countdown";
import Menu from "./Menu";
import Content from "./Content";
import {useState} from "react";

function App() {
    const [isOpened, setIsOpened] = useState(false);

  return (
      <div className="App">
          <div className="app-background">
              <div className={"app-top"}>
                  <img src={logo} className="app-ornement" alt="logo"/>
                  <div className="app-countdown">
                      <Countdown/>
                  </div>
              </div>
              <Menu setIsOpened={setIsOpened}/>
              <Content isOpened={isOpened} setIsOpened={setIsOpened}/>
          </div>
      </div>
  );
}

export default App;
