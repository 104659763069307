import React from 'react';
import ReactDOM from 'react-dom/client'
import { Client } from 'appwrite';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import App from './App';
import Theme from "./Theme";

const client = new Client();

client.setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('66e3f1db00271f99471e');

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Theme>
          <QueryClientProvider client={queryClient}>
              <App />
          </QueryClientProvider>
      </Theme>
  </React.StrictMode>
);
